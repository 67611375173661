import React, { useState, useEffect, useContext } from "react"

import "./style.scss"
import CheckoutInput from "../CheckoutInput"
import { formLabels, placeholderLabels } from "./mockedData"
import CheckoutSelect from "../CheckoutSelect"
import { states } from "../ContactsPrescription/mockedData"
import { selectDefaultValue } from "../../services/utils"
import { zipRegEx } from "../../utils/variables"
import CheckoutContext from "../../context/checkoutContext"
import RequiredMessage from '../RequiredMessage'
import useNewRxFlowDesktop from '../../utils/useNewRxFlowDesktop'

const NewShippingAddress = ({ isValid, setIsValid, setAddress, emojiErrors, setEmojiErrors }) => {
  const { isRequiredMessage, isAddressShouldReset, setIsAddressShouldReset } = useContext(CheckoutContext)
  const [addresses, setAddresses] = useState([])
  const [newAddress, setNewAddress] = useState({
    first_name: "",
    last_name: "",
    company: "",
    address: "",
    address_opt: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  })

  const optionalFields = ["company", "address_opt", "phone"]

  const isNewRxDesktop = useNewRxFlowDesktop()

  const handleInputChange = ({ target: { value, name } }) => {
    setEmojiErrors?.({...emojiErrors, [name]: false})
    setNewAddress(prev => ({ ...prev, [name]: value }))
  }

  const loqate = () => {
    // TODO: hook up int'l addresses

    var url = 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws';
    var params = '';
        params += "&Key=" + encodeURIComponent("HG95-GT79-MD54-PP92");
        params += "&Text=" + encodeURIComponent(newAddress.address);
        params += "&Countries=" + encodeURIComponent("USA");
        params += "&IsMiddleware=" + encodeURIComponent(false);
        params += "&Limit=" + encodeURIComponent(10);
        params += "&Language=" + encodeURIComponent("en-gb");
    var http = new XMLHttpRequest();
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.onreadystatechange = function() {
      if(http.readyState == 4 && http.status == 200) {
          var response = JSON.parse(http.responseText);
          // Test for an error
          if (response.Items.length == 1 && typeof(response.Items[0].Error) != "undefined") {
            // Show the error message
            // alert(response.Items[0].Description);
          }
          else {
            // Check if there were any items found
            if (response.Items.length >= 0)
              setAddresses(response.Items)
          }
        }
      }
      http.send(params);
  }

  useEffect(() => {
    loqate()
  }, [newAddress.address])

  const autoFillAddress = (address) => {
    console.log(address)
    const splitCityZip = address.Description.match(/[\d\.]+|\D+/g);
    const addressCity = splitCityZip[0].split(" ")
    addressCity.pop()
    const state = addressCity[addressCity.length - 1]
    addressCity.pop()
    const city =  addressCity.join(" ")
    setAddresses([])
    setNewAddress({...newAddress, address: address.Text, zip: splitCityZip[1], city: city, state: state})
  }






  useEffect(() => {
    const isFulfilled = !Object.entries(newAddress).some(
      ([key, value]) => !optionalFields.includes(key) && !value
    )

    const isValidZip = zipRegEx.test(newAddress.zip)

    if (isFulfilled && isValidZip) {
      if (!isValid) {
        setIsValid(true)
      }

      return setAddress(newAddress)
    }

    if (isValid && (!isFulfilled || !isValidZip)) {
      setIsValid(false)
    }
  }, [newAddress])

  useEffect(() => {
    if (isAddressShouldReset) {
      setNewAddress({
        first_name: "",
        last_name: "",
        company: "",
        address: "",
        address_opt: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
      })
      setIsAddressShouldReset(false)
    }
  }, [isAddressShouldReset]);

  return (
    <div className="new-shipping-address" onClick={() => setAddresses([])}>
      <div className="two-inputs">
        <div>
          <CheckoutInput
            name="first_name"
            value={newAddress.first_name}
            onChange={handleInputChange}
            placeholder={placeholderLabels.first_name}
            label={formLabels.first_name}
            />
          {newAddress && !newAddress.first_name && isRequiredMessage && <RequiredMessage />}
          {emojiErrors?.first_name && <div className="error-block">Emojis are not allowed</div>}
        </div>
        <div>
          <CheckoutInput
            name="last_name"
            value={newAddress.last_name}
            onChange={handleInputChange}
            placeholder={placeholderLabels.last_name}
            label={formLabels.last_name}
            />
          {newAddress && !newAddress.last_name && isRequiredMessage && <RequiredMessage />}
          {emojiErrors?.last_name && <div className="error-block">Emojis are not allowed</div>}
        </div>
      </div>
      <CheckoutInput
        name="company"
        value={newAddress.company}
        onChange={handleInputChange}
        placeholder={placeholderLabels.company}
        label={formLabels.company}
      />
      <div>
        <CheckoutInput
          name="address"
          value={newAddress.address}
          placeholder={placeholderLabels.address}
          label={formLabels.address}
          onChange={handleInputChange}
        />
        {newAddress && !newAddress.address && isRequiredMessage && <RequiredMessage />}

        { addresses.length >= 1 && newAddress.address.length >=1 &&
          <div className="address-dropdown">
            {addresses.map((address) => {
              return <p className="address" onClick={() => autoFillAddress(address)} ><strong>{address.Text}</strong> {address.Description}</p>
            })}
          </div>
        }

      </div>
      <CheckoutInput
        name="address_opt"
        value={newAddress.address_opt}
        onChange={handleInputChange}
        placeholder={placeholderLabels.address_opt}
        label={formLabels.address_opt}
      />
      <div className="three-inputs">
        <div>
          <CheckoutInput
          name="city"
          value={newAddress.city}
          onChange={handleInputChange}
          placeholder={placeholderLabels.city}
          label={formLabels.city}
          />
        {newAddress && !newAddress.city && isRequiredMessage && <RequiredMessage />}
        </div>
        <div>
        <CheckoutSelect
          label="State"
          value={selectDefaultValue(states, newAddress.state) || null}
          onChange={({ value }) =>
            setNewAddress(prev => ({ ...prev, state: value }))
          }
          options={states}
          filterOption={({ label }, input) => {
            if (!input) {
              return true
            }

            return label.toLowerCase().startsWith(input.toLowerCase())
          }}
          placeholder={isNewRxDesktop ? 'Select your state' : 'Select...'}
          />
          {newAddress && !newAddress.state && isRequiredMessage && <RequiredMessage />}
        </div>
        <div>
          <CheckoutInput
            name="zip"
            value={newAddress.zip}
            onChange={handleInputChange}
            placeholder={placeholderLabels.zip}
            label={formLabels.zip}
          />
          {newAddress && !newAddress.zip && isRequiredMessage && <RequiredMessage />}
          {newAddress && (newAddress.zip && !zipRegEx.test(newAddress.zip)) && isRequiredMessage && <RequiredMessage requiredText={'Invalid zip code'} />}
          </div>
      </div>
      <CheckoutInput
        name="phone"
        value={newAddress.phone}
        onChange={handleInputChange}
        placeholder={placeholderLabels.phone}
        label={formLabels.phone}
      />
    </div>
  )
}

export default NewShippingAddress
