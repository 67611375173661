import React, {useState, useContext, useEffect, useMemo} from "react"
import { Modal } from "react-bootstrap"
import CartContext from "../../context/cartContext"
import { selectDefaultValue } from "../../services/utils"
import { getPowerOptions } from "../ContactsDetailsMobile"
import NewCustomSelect from "../NewCustomSelect"
import RequiredMessage from "../RequiredMessage"
import { EnterRXWrapper } from "../RXComponent"

import "./style.scss"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import {productInfo as prescriptionData} from "../Intake/Prescription/mockedData";

const EditRxModal = ({ show, onHide, description, isHydro, isSkyhy, cartItemId, prescription, price, nonSubscription }) => {
  const { updateRx, cartItems, setOrder, order } = useContext(CartContext)
  const [rightPowerOptions] = useState(getPowerOptions("right", isHydro, isSkyhy, nonSubscription))
  const [leftPowerOptions] = useState(getPowerOptions("left", isHydro, isSkyhy, nonSubscription))
  const [newPrescription, setNewPrescription] = useState(prescription)
  const prescriptionSettings = useMemo(
    () => prescriptionData[cartItems.find(e => e.cart_item_id === cartItemId).cc_product_id],
    [prescriptionData, cartItemId, cartItems]
  )
  const leftEyePowerOptions = prescriptionSettings?.eye_powers && [
    { value: 'oneEye', label: `I don't need left eye lenses` },
    ...prescriptionSettings.eye_powers
  ]
  const rightEyePowerOptions = prescriptionSettings?.eye_powers && [
    { value: 'oneEye', label: `I don't need right eye lenses` },
    ...prescriptionSettings.eye_powers
  ]
  const [isEmptyValues, setIsEmptyValues] = useState(false)
  const isNewRxDesktop = useNewRxFlowDesktop()

  const handlePowerChange = (eye, value) => {
    const newEye = { ...newPrescription[eye], power: value }
    const prescription = { ...newPrescription, [eye]: newEye }
    
    setNewPrescription(prescription)
  }

  const handleAddPowerChange = (eye, value) => {
    const newEye = { ...newPrescription[eye], addPower: value }
    const prescription = { ...newPrescription, [eye]: newEye }

    setNewPrescription(prescription)
  }

  const handleCylinderChange = (eye, value) => {
    const newEye = { ...newPrescription[eye], cylinder: value }
    const prescription = { ...newPrescription, [eye]: newEye }

    setNewPrescription(prescription)
  }

  const handleAxisChange = (eye, value) => {
    const newEye = { ...newPrescription[eye], axis: value }
    const prescription = { ...newPrescription, [eye]: newEye }

    setNewPrescription(prescription)
  }

  const handleBaseCurveChange = (eye, value) => {
    const newEye = { ...newPrescription[eye], baseCurve: value }
    const prescription = { ...newPrescription, [eye]: newEye }

    setNewPrescription(prescription)
  }
  
  const handleSave = () => {
    if (
      newPrescription?.rightEye?.power === "oneEye" &&
      (
        prescriptionSettings?.axis && !newPrescription.leftEye.axis ||
        prescriptionSettings?.cylinder && !newPrescription.leftEye.cylinder ||
        prescriptionSettings?.add_power && !newPrescription.leftEye.addPower ||
        Array.isArray(prescriptionSettings?.base_curve_values) &&
        prescriptionSettings?.base_curve_values.length > 1 &&
        !newPrescription.leftEye.baseCurve
      ) ||
      newPrescription?.leftEye?.power === "oneEye" &&
      (
        prescriptionSettings?.axis && !newPrescription.rightEye.axis ||
        prescriptionSettings?.cylinder && !newPrescription.rightEye.cylinder ||
        prescriptionSettings?.add_power && !newPrescription.rightEye.addPower ||
        Array.isArray(prescriptionSettings?.base_curve_values) &&
        prescriptionSettings?.base_curve_values.length > 1 &&
        !newPrescription.rightEye.baseCurve
      ) ||
      newPrescription?.leftEye?.power !== "oneEye" && newPrescription?.rightEye?.power !== "oneEye" &&
      (
        prescriptionSettings?.axis && (!newPrescription.leftEye.axis || !newPrescription.rightEye.axis) ||
        prescriptionSettings?.cylinder && (!newPrescription.leftEye.cylinder || !newPrescription.rightEye.cylinder) ||
        prescriptionSettings?.add_power && (!newPrescription.leftEye.addPower || !newPrescription.rightEye.addPower) ||
        Array.isArray(prescriptionSettings?.base_curve_values) && prescriptionSettings?.base_curve_values.length > 1 &&
        (!newPrescription.leftEye.baseCurve || !newPrescription.rightEye.baseCurve)
      ) ||
      !(newPrescription.rightEye.power || newPrescription.leftEye.power) ||
      (newPrescription?.rightEye?.power === 'oneEye' && newPrescription?.leftEye?.power === 'oneEye')
    ) {
      setIsEmptyValues(true)
      return
    }

    updateRx('prescription', newPrescription, price, cartItemId)
    setOrder({...order, prescription: newPrescription})
    onHide()
  }

  useEffect(() => {
    if(newPrescription.rightEye.power && newPrescription.leftEye.power) setIsEmptyValues(false)
  }, [prescription, newPrescription.rightEye.power, newPrescription.leftEye.power])

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setNewPrescription(prescription)
      }, 250)
    }
  }, [show]);

  return (
    <Modal show={show} className={`edit-rx-modal ${isNewRxDesktop ? "new-rx-desktop" : ""}`} onHide={() => onHide()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Title>Edit Your Prescription</Modal.Title>

      <Modal.Body>
        <EditRxField
          values={{
            rightEye: selectDefaultValue(rightEyePowerOptions || rightPowerOptions, (newPrescription?.rightEye?.power || 'oneEye')),
            leftEye: selectDefaultValue(leftEyePowerOptions || leftPowerOptions, newPrescription?.leftEye?.power || 'oneEye')
          }}
          options={{
            rightEye: rightEyePowerOptions || rightPowerOptions,
            leftEye: leftEyePowerOptions || leftPowerOptions
          }}
          labels={{rightEye: "Right Eye (OD) Power", leftEye: "Left Eye (OS) Power"}}
          name="power"
          onChange={handlePowerChange}
          requiredError={{
            rightEye: !newPrescription?.rightEye?.power && newPrescription?.rightEye?.power !== "oneEye" && isEmptyValues,
            leftEye: !newPrescription?.leftEye?.power && newPrescription?.leftEye?.power !== "oneEye" && isEmptyValues
          }}/>
        {prescriptionSettings?.add_power &&
          <EditRxField
            values={{
              rightEye: selectDefaultValue(prescriptionSettings?.add_power, newPrescription?.rightEye?.addPower),
              leftEye: selectDefaultValue(prescriptionSettings?.add_power, newPrescription?.leftEye?.addPower)
            }}
            options={{
              rightEye: prescriptionSettings.add_power,
              leftEye: prescriptionSettings.add_power
            }}
            labels={{rightEye: "Right Eye (OD) Add Power", leftEye: "Left Eye (OS) Add Power"}}
            name="addPower"
            onChange={handleAddPowerChange}
            requiredError={{
              rightEye: !newPrescription?.rightEye?.addPower && newPrescription?.rightEye?.power !== "oneEye" && isEmptyValues,
              leftEye: !newPrescription?.leftEye?.addPower && newPrescription?.leftEye?.power !== "oneEye" && isEmptyValues
            }}
            disabled={{
              rightEye: newPrescription?.rightEye?.power === "oneEye",
              leftEye: newPrescription?.leftEye?.power === "oneEye"
            }}/>
        }
        {prescriptionSettings?.cylinder &&
          <EditRxField
            values={{
              rightEye: selectDefaultValue(prescriptionSettings?.cylinder, newPrescription?.rightEye?.cylinder),
              leftEye: selectDefaultValue(prescriptionSettings?.cylinder, newPrescription?.leftEye?.cylinder)
            }}
            options={{
              rightEye: prescriptionSettings.cylinder,
              leftEye: prescriptionSettings.cylinder
            }}
            labels={{rightEye: "Right Eye (OD) Cylinder", leftEye: "Left Eye (OS) Cylinder"}}
            name="cylinder"
            onChange={handleCylinderChange}
            requiredError={{
              rightEye: !newPrescription.rightEye.cylinder && newPrescription.rightEye.power !== "oneEye" && isEmptyValues,
              leftEye: !newPrescription.leftEye.cylinder && newPrescription.leftEye.power !== "oneEye" && isEmptyValues
            }}
            disabled={{
              rightEye: newPrescription?.rightEye?.power === "oneEye",
              leftEye: newPrescription?.leftEye?.power === "oneEye"
            }}/>
        }
        {prescriptionSettings?.axis &&
          <EditRxField
            values={{
              rightEye: selectDefaultValue(prescriptionSettings?.axis, newPrescription?.rightEye?.axis),
              leftEye: selectDefaultValue(prescriptionSettings?.axis, newPrescription?.leftEye?.axis)
            }}
            options={{
              rightEye: prescriptionSettings.axis,
              leftEye: prescriptionSettings.axis
            }}
            labels={{rightEye: "Right Eye (OD) Axis", leftEye: "Left Eye (OS) Axis"}}
            name="axis"
            onChange={handleAxisChange}
            requiredError={{
              rightEye: !newPrescription.rightEye.axis && newPrescription.rightEye.power !== "oneEye" && isEmptyValues,
              leftEye: !newPrescription.leftEye.axis && newPrescription.leftEye.power !== "oneEye" && isEmptyValues
            }}
            disabled={{
              rightEye: newPrescription?.rightEye?.power === "oneEye",
              leftEye: newPrescription?.leftEye?.power === "oneEye"
            }}/>
        }
        {Array.isArray(prescriptionSettings?.base_curve_values) &&
          prescriptionSettings.base_curve_values.length > 1 &&
          !nonSubscription &&
          <EditRxField
            values={{
              rightEye: selectDefaultValue(prescriptionSettings?.base_curve_values, String(newPrescription?.rightEye?.baseCurve)),
              leftEye: selectDefaultValue(prescriptionSettings?.base_curve_values, String(newPrescription?.leftEye?.baseCurve))
            }}
            options={{
              rightEye: prescriptionSettings.base_curve_values,
              leftEye: prescriptionSettings.base_curve_values
            }}
            labels={{rightEye: "Right Eye (OD) Base Curve", leftEye: "Left Eye (OS) Base Curve"}}
            name="baseCurve"
            onChange={handleBaseCurveChange}
            requiredError={{
              rightEye: !newPrescription.rightEye.baseCurve && newPrescription.rightEye.power !== "oneEye" && isEmptyValues,
              leftEye: !newPrescription.leftEye.baseCurve && newPrescription.leftEye.power !== "oneEye" && isEmptyValues
            }}
            disabled={{
              rightEye: newPrescription?.rightEye?.power === "oneEye",
              leftEye: newPrescription?.leftEye?.power === "oneEye"
            }}/>
        }
        <p className="hubble-tip">{description}</p>
        <button
          className="btn btn-primary save-btn"
          onClick={() => handleSave()}
        >
          Save Changes
        </button>
      </Modal.Body>
    </Modal>
  )
}

const EditRxField = ({values, options, labels, name, onChange, disabled, error, requiredError}) => {
  const isNewRxDesktop = useNewRxFlowDesktop()

  return (
    <EnterRXWrapper color="purple">
      <div className={`power-row ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
        <div className="select-container">
          <p className="title text h8 bulky ocean-blue">{labels.rightEye}</p>
          <NewCustomSelect
            value={values.rightEye}
            name={name}
            onChange={({ value }) => onChange("rightEye", value)}
            options={options.rightEye}
            isDisabled={disabled?.rightEye}
            error={error?.rightEye}
          />
          {requiredError.rightEye && <RequiredMessage />}
        </div>
        <div className="select-container">
          <p className="title text h8 bulky ocean-blue">{labels.leftEye}</p>
          <NewCustomSelect
            value={values.leftEye}
            name={name}
            onChange={({ value }) => onChange("leftEye", value)}
            options={options.leftEye}
            isDisabled={disabled?.leftEye}
            error={error?.leftEye}
          />
          {requiredError.leftEye && <RequiredMessage />}
        </div>
      </div>
    </EnterRXWrapper>
  )
}

export default EditRxModal
