import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"

import SliderPagination from "../SliderPagination"
import { colorTags } from "../../services/data"
import WebpImage from "../WebpImage"

import "./style.scss"
import ProductTag from "../ProductTag"
import useNewRxFlow from "../../utils/useNewRxFlow";
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const ProductSlider = ({ slides, type, isKidsImage, product, currentVariant, tagText, tagColor, forcePagination}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const sliderRef = useRef()
  const isClassic = product?.title?.toLowerCase()?.includes("classic")
  const isHydroOrSkyhy = product?.title?.toLowerCase()?.includes("hydro") || product?.title?.toLowerCase()?.includes("skyhy")
  const settings = {
    speed: 500,
    draggable: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => {
      setCurrentPage(next + 1)
    },
  }
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  useEffect(() => {
    setTimeout(() => {
      if(currentVariant && sliderRef?.current) {
        setCurrentPage(0)
        sliderRef.current.slickGoTo(0)
      }
    }, 100)
  }, [currentVariant])

  return (
    <div className="product-slider-container" data-type={type}>
      {isHydroOrSkyhy && <ProductTag text={tagText} color={tagColor} />}
      <Slider ref={sliderRef} className={`product-slider ${isNewRx || isNewRxDesktop ? "new-rx" : ""}`} {...settings}>
        {slides.map((image, index) => {
          const isAvenovaAccessory = product?.title === "Avenova OTC Antimicrobial Spray Solution" && index === 1

          return (
          <div
            className="carousel-item"
            key={index}
            data-contain={isClassic ? index % 2 === 0 : ((type === "accessory" && !isAvenovaAccessory) || (type === "contacts" && !isClassic))}
            data-center={isClassic ? index % 2 !== 0 : (type === "accessory" || (type === "contacts" && !isClassic))}
            data-size={((type === "sunglasses" || type === "glasses") && index < 4) ? "cover" : ""}
          >
            <div className={isKidsImage ? "image-container kids" : "image-container"}>
              <WebpImage fileName={image} alt={`${product?.title} ${type} ${currentVariant?.title ? currentVariant?.title : '' }`} className="product-image"/>
            </div>
          </div>
        )})}
      </Slider>
      {(slides.length > 1 || forcePagination) &&
        <SliderPagination
          sliderRef={sliderRef}
          currentPage={currentPage}
          allPages={slides.length}
          noPages
          color={colorTags[type]}
        />
      }
    </div>
  )
}

export default ProductSlider
