import React from "react"

const ProductDetailsMobile = ({ item, type }) => {
  const { subheader } = item

  const banner =
    typeof document !== "undefined"
      ? document.querySelector(".discount-banner")
      : null

  return (
    <div
      className={`product-details-container ${
        !!banner ? "include-banner-height" : ""
      }`}
      data-type={type}
    >
      <div className="product-details">
        <ContactsDetails item={item} />
        <p
          className="text h7 neue description hi"
          dangerouslySetInnerHTML={{ __html: subheader }}
        />
      </div>
    </div>
  )
}

const ContactsDetails = ({ item }) => {
  const { baseCurve, diameter, material, manufacturer } = item

  return (
    <div className="details-container" data-type="contacts">
      <DetailItem title="Base Curve (BC)" type="measurements">
        <span className="text h7 neue">{baseCurve}</span>
      </DetailItem>
      <DetailItem title="Material" type="measurements">
        <span className="text h7 neue">{material}</span>
      </DetailItem>
      <DetailItem title="Diameter (DIA)" type="measurements">
        <span className="text h7 neue">{diameter}</span>
      </DetailItem>
      <DetailItem title="Manufacturer" type="measurements">
        <span className="text h7 neue">{manufacturer}</span>
      </DetailItem>
    </div>
  )
}

const DetailItem = ({ title, children, type }) => (
  <div className="detail-item new-rx" data-type={type}>
    <p className="detail-head text h11 neue-bold uppercase">{title}</p>
    <div className="detail-body">{children}</div>
  </div>
)

export default ProductDetailsMobile
